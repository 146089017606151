.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 0px;
  @media (min-width: $lg) {
    height: 110px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  &--fixed{
    position: fixed;
    width: 100%;
    background-color: $white;
    animation: scale-top-bottom 0.3s ease-in;
    top: 0;
    z-index: 99;

  }

  &__nav {
    width: 100%;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 92px;
    padding: 50px 10px;
    background: $white-found url("/img/bg-exams.png") right 69px;
    box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.24);
    display: none;

    @media (min-width: $sm) {
      width: unset;
      min-width: 400px;
    }

    @media (min-width: $lg) {
      flex-direction: row;
      background: unset;
      position: unset;
      padding: unset;
      box-shadow: unset;
      display: flex !important;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      @media (min-width: $lg) {
        flex-direction: row;
        gap: unset;
      }

      @media (min-width: $xl) {
        margin-right: 60px;
      }
    }
  }

  &__nav-item {
    font-size: 1rem;  
    padding: 5px 0px;
    font-weight: 600;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -9%;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid $yellow;
      padding: 5px;
    }

    &:hover {
      transition: all 0.2s;
      color: $blue-sky;
    }

    @media (min-width: $lg){
      padding: 0px 8px;
      font-weight: normal;
      font-size: 0.9rem;

      &::after {
        display: none;
      }

      @media (min-width: $xl) {
        padding: 0px 10px;
        font-size: 1rem;
      }
    }

    img {
      margin-right: 25px;
    }

    &--active {
      color: $blue-bondi;
      font-weight: 600;
    }
  }
  
  &__search{
    width: 25px;
    height: 25px;
    align-self: center;
    margin-right: 15px;
    &_lupe{
      width: 100%;
      height: 100%;
      text-align: center;
    }
    &:hover{
      -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
  }

  &__contact {
    color: $blue-bondi;
    font-weight: bold;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;

    @media (min-width: $lg) {
      margin-top: unset;
      align-items: unset;
      flex-direction: row;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 1rem;
      margin: 0px 0px 20px 0px;

      @media (min-width: $lg) {
        margin: 0px 15px 0px 0px;
      }
    }

    &_phone {
      padding: 0px 15px;
      height: 25px;
    }

    .fa-whatsapp {
      color: $yellow;
      font-size: 1.3rem;
      margin-right: 8px;
    }
  }

  &__toggle-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.6rem;
      color: $blue-sky;

      &:hover {
        color: $blue-bondi;
      }
    }

    @media (min-width: $lg) {
      display: none;
    }
  }

  &__search-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    text-align: center;
    background: rgba(23, 35, 47, 0.8);
    margin: 0;
    z-index: 100;

    &_btn-close{
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-size: 30px;
      position: relative;
      right: 10px;
      top: 10px;
      float: right;
    }

    &_searchbox{
      width: 100%;
      height: calc(100vh - 57px);
      display: flex;
      justify-content: center;
      align-content: center;
      form {
        width: 40%;
        display: flex;
        max-height: 56px;
        align-self: center;
        @media (max-width: 700px) {
            width: 80%;
        }
      }
      
      input{
        border-radius: 21px 0 0 21px;
        padding: 20px 30px;
        border: none;
        width: 80%;
      }
      button{
        border-radius: 0 21px 21px 0;
        border: none;
        align-self: center;
        margin-top: 0px;
        height: 55px;
      }
    }
  }
}
