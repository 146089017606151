* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: unset;
  padding: unset;
  box-sizing: border-box;
}


body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

a {
  color: unset;
  text-decoration: unset;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

button {
  background-color: unset;
  border: unset;
  color: inherit;
  font-weight: inherit;
  text-transform: inherit;
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

p{
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: normal;
}
