.blog-internal {
  padding: 60px 0px 40px 0px;
  background: url(/img/bg-blog.png) no-repeat;
  background-position: center center;
  background-size: cover;

  .container {
    @media (min-width: $sm) {
      padding: 0px 50px;
    }
  }

  &__title {
    font-size: 2rem;
    padding: 0px 20px 20px 0px;
  }

  &__date {
    color: $orange-class;
    font-size: 0.9rem;
    font-weight: 500;
    padding-bottom: 5px;
  }
}

.blog-internal-intro {
  &__image {
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    max-height: 359px;
    margin: 30px 0px;

    img {
      width: 100%;
    }
  }
}

.blog-internal-post {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 970px;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 70px;
  }

  &__socials {
    color: $pink;
    font-weight: 600;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @media (min-width: $md) {
        flex-direction: column;
      }

      li {
        font-size: 0.9rem;

        &:first-child{
          font-size: 0.8rem;
        }
      }

      i{
        font-size: 1.2rem;
      }
    }
  }

  &__link-social {
    &:hover {
      i {
        transform: scale(1.5);
      }
    }
  }

  i{
    transition: all 0.3s ease;
  }

  p {
    font-size: 1rem;
    opacity: 0.7;
    line-height: 28px;
  }

  &__image {
    padding: 25px 0px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}

.post-comments {
  padding: 60px 10px;
  max-width: 800px;
  margin: 0 auto;

  .container {
    padding: unset;
  }

  &__review {
    padding: 10px 30px;
    max-height: 400px;
    background: #f9f7f7;
    margin: 13px 0px;

    h3 {
      font-size: 1rem;
      padding: 5px 0px;
    }

    p {
      font-size: 0.9rem;
      padding: 5px 0px;
    }

    span {
      &::before {
        content: '-';
        padding: 0px 10px;
      }
    }
  }

  &__container-inputs {
    display: flex;
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__formulario {
    padding: 20px 0px;

    h3 {
      padding: 20px 0px;
    }

    input,
    textarea {
      font-family: 'Montserrat', sans-serif;
      padding: 13px 15px;
      border-radius: 7px;
      width: 100%;
      border: unset;
      box-shadow: 4px 4px 14px -1px rgba(0, 0, 0, 0.2);
      margin: 10px 0px;
      resize: none;
    }
    .btn-blue {
      margin: 15px 0px;
      max-width: 200px;
    }

    form {
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.posts-plus{
  &__title{
    margin-top: 30px;
    text-align: center;
    font-size: 1.4rem;

    @media (min-width: $md) {
      text-align: left;
    }
  }
}
