.container {
  max-width: 1240px;
  height: 100%;
  margin: 0 auto;
  padding: 0px 15px;
}

.wrapper{
  @media (max-width: $lg) {
    padding: 0px 10px;
  }
}

.btn-blue {
  background-color: $blue-bondi;
  color: $white;
  text-align: center;
  padding: 13px 25px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0px 10px;
  font-size: 0.9rem;
  box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.29);

  &:hover {
    transition: 0.3s;
    opacity: 0.9;
  }
}

.btn-yellow{
  background-color: $yellow;
  color: $black;
  text-align: center;
  padding: 13px 25px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.29);
  margin-top: 30px;

  &:hover{
    background: $blue-light;
    transition: all 0.5s;
    color: $white;
  }
}
