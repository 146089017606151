@keyframes scale-top-bottom {
        0% {
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }
        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        }
}

@keyframes glow-external {
        from {
                box-shadow: 0 0 2px -2px $yellow;
        }
        to {
                box-shadow: 0 0 2px 2px $yellow;
        }
}
