.main {
  background-size: cover;
  padding: 50px 0px;
  text-align: center;

  @media (min-width: $md) {
    text-align: left;
    background: url(/img/banner-main.png) no-repeat;
    background-position: center center;
  }

  @media (min-width: $xxl) {
    background-size: contain;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media (min-width: $md) {
      flex-direction: row;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $md) {
      align-items: flex-start;
    }

    p:first-child {
      font-weight: 600;
    }

    p {
      font-weight: bold;
    }
    span {
      color: $blue-bondi;
    }

    h1 {
      font-size: 3.5rem;
      margin: 23px 0px;
      @media (min-width: $md) {
        padding-right: 30px;
      }
    }
  }

  &__image {
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
      max-height: 350px;
      max-width: 250px;
      @media (min-width: $lg) {
        max-width: unset;
        max-height: unset;
        
      }
    }
  }
}

.about {
  background: url(/img/about-clinical.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -350px;
  padding: 40px 0px;

  @media (min-width: $lg) {
    background-position: -185px;
  }

  @media (min-width: $xl) {
    background-position: unset;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    display: none;

    @media (min-width: $md) {
      display: block;
    }

    img {
      max-height: 640px;

      @media (max-width: $xl) {
        width: 100%;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    @media (min-width: $md) {
      padding: 0px 40px;
    }
  }

  &__text-title {
    font-size: 2.5rem;
    text-align: center;

    @media (min-width: $md) {
      text-align: left;
      margin-left: 55px;
    }
    span {
      color: $blue-sky;
    }
  }
}

.about-cards {
  padding-top: 70px;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media (min-width: $sm) {
      flex-direction: row;
    }
  }

  &__block-text {
    max-width: 325px;
    text-align: center;
    margin-top: 30px;

    @media (min-width: $sm) {
      text-align: left;
      margin-top: unset;
      margin-left: 50px;
    }

    h3 {
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      margin-top: 23px;
    }

    &--mission{
      h3{
        color: $yellow;
      }
    }

    &--especial{
      h3{
        color: $blue-light;
     }
    }
    &--actuation{
      h3{
        color: $blue-sky;
      }
    }
  }

  &__block-image {
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.29);
    background: $white;

    img {
      padding: 0px 10px;
      max-width: 75px;
    }
  }
}

.locale {
  background: $white;
  background: url("/img/radius-float.png") no-repeat;
  background-position: bottom 1% left 0%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__wrapper {
    padding: 40px 0px;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 755px;
    background: $white-found url(/img/bg-balao.png) no-repeat;
    background-position: left top;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.03);
    z-index: 1;

    @media (min-width: $lg) {
      border-radius: 0px 326px 129px 300px;
    }
  }
}

.locale-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 80px;
 
  @media (min-width: $sm) {
    flex-direction: row;
  }

  @media (min-width: $lg) {
    padding: 0px 45px;
  }

  &__info {
    width: 100%;
    max-width: 533px;
  }

  &__info-title {
    font-size: 2.3rem;
    line-height: 42px;

    span {
      color: $blue-sky;
    }
  }

  &__info-desc {
    margin-top: 23px;
    padding-bottom: 5px;
    color: $gray-text;
  }

  .btn-blue {
    margin-top: 50px;
    margin-left: unset;
    max-width: 155px;
    text-align: center;
  }

  &__info-image {
    img {
      width: 100%;
      @media (min-width: $md) {
        min-width: 390px;
      }
    }
  }
}

.locale-find {
  width: 100%;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $white;
  padding-top: 50px;

  @media (min-width: $lg) {
    align-self: flex-end;
    flex-direction: row;
    height: 580px;
    justify-content: flex-start;
    padding: unset;
  }

  @media (min-width: $xl) {
    border-radius: 146px 0px 0px 316px;
    width: 85%;
  }

  img {
    padding: 30px;
    border-radius: 50%;
    box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.29);
  }

  &__info {
    display: flex;
    line-height: 30px;
    gap: 40px;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;

    @media (min-width: $xl) {
      padding: 0px 100px;
      flex-direction: row;
      align-items: flex-start;
    }
    article {
      text-align: center;
      @media (min-width: $xl) {
        text-align: left;
      }
      h2 {
        margin-bottom: 30px;
      }

      a{
        font-size: 0.9rem;
        transition: ease-in-out 0.5s;
          &:hover{
            color: $blue-sky;
            text-decoration: underline;
          }
      }

      p {
        margin-bottom: 30px;
        font-weight: bold;
      }
    }
  }

  &__map {
    height: 100%;
    max-height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.doctors {
  padding: 60px 0px;
  width: 100%;
  position: relative;
  background: url("/img/bg-doctors.png") no-repeat center;
  background-size: cover;
  text-align: center;

  @media (min-width: $lg) {
    text-align: unset;
  }
  @media (min-width: $xxl) {
    border-radius: 196px 544px 748px 0px;
    width: 104%;

    &::after {
      z-index: -1;
      content: "";
      position: absolute;
      left: -35px;
      top: 0;
      height: 100%;
      width: 100%;
      border-right: 100px solid rgba(112, 112, 112, 0.5);
      opacity: 0.9;
      border-radius: 271px 600px 800px 0px;
      overflow: hidden;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 2.3rem;
    color: $white;
    padding: 30px 0px;
    line-height: 50px;
    
  }

  h3 {
    color: $white;
    font-size: 1.8rem;
  }
  span {
    color: $yellow;
  }

  &__reference{
    text-align: center;
  }
}

.doctors-submission {
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 25px;

  @media (min-width: $md) {
    flex-direction: row;
  }

  &__self {
    height: 300px;
    border-radius: 190px 0px 90px 0px;
    background: url("/img/Doutor.png") no-repeat;
    background-size: contain;
    width: 100%;
    justify-self: center;
    background-position: center;

    @media (min-width: $lg) {
      height: 550px;
      width: 550px;
    }
  }

  &__desc {
    display: flex;
    align-self: center;
    flex-direction: column;
    max-width: 590px;
    span {
      color: $yellow;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
    }

    h2 {
      color: $white;
      font-size: 2rem;
      padding: 13px 0px 20px 0px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 600;
      color: $white;
      line-height: 22px;
    }
  }

  &__certificates {
    padding: 20px 0px;
    article {
      max-height: 140px;
      display: flex;
      align-items: center;
      padding: 18px 15px;
      background: $white;
      color: $black;
      border-radius: 39px;
      margin-bottom: 15px;
      gap: 20px;

      @media (min-width: $md) {
        max-height: 60px;
      }

      p {
        color: $black;
        line-height: 22px;
      }
    }
  }
}

.doctors-differentials {
  padding: 70px 0px 20px 0px;
  text-align: center;
  &__grid {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (min-width: $md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__card {
    padding: 70px 20px;
    background: $white-found;
    border-radius: 22px;
    text-align: center;

    p {
      line-height: 22px;
    }
  }
}

.doctors-formation {
  text-align: center;
  padding: 80px 0px 0px 0px;
  @media (min-width: $md) {
    padding: 80px 0px 80px 0px;
  }
  p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 auto;
    color: $yellow;
    text-transform: uppercase;
  }

  h2 {
    span {
      color: $blue-light;
    }
  }

  a {
    text-decoration: underline;
    font-size: clamp(1rem, 3vw, 1.8rem);
    color: $yellow;
    margin-top: 20px;
    padding-bottom: 50px;
    transition: ease 0.3s;
    &:hover{
      color: $white-found;
      text-decoration: none;

    }
  }

  &__qualifications {
    display: grid;
    gap: 25px;
    padding: 30px 0px;

    @media (min-width: $md) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px 40px;
      color: $white;
      font-weight: 600;
      font-size: 0.9rem;
      font-family: "Roboto", sans-serif;
      text-align: left;
      margin-left: 20px;

      &::after {
        content: "\f058";
        position: absolute;
        left: -10px;
        padding: 0px 10px;
        font-family: "Font Awesome 5 Free";
        color: $yellow;
        font-size: 35px;
      }
    }
  }
}

.lab {
  display: flex;
  padding: 80px 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  @media (min-width: $md) {
    flex-direction: unset;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 535px;
    min-height: 300px;

    h2 {
      font-size: 2.3rem;
      color: $black;
    }

    span {
      color: $yellow;
    }
  }

  p {
    color: #315171;
    line-height: 22px;
  }

  a {
    max-width: 155px;
    margin-left: unset;
  }

  &__img {
    img {
      width: 100%;
    }
  }
}

.exams {
  padding: 5px 0px 0px 0px;
  display: flex;
   
  &__wrapper {
    padding: 75px 10px 20px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 6px 6px 6px rgba(0, 0, 0, 0.03);
    background: $white-found;
    width: 100%;
    top: 30px;

    @media (min-width: $sm) {
      justify-content: center;
      top: 150px;
    }

    @media (min-width: $xl) {
      border-radius: 320px 60px 89px 0px;
    }

    h2 {
      font-size: 2.3rem;
      text-align: center;
      margin-bottom: 50px;

      span {
        color: $blue-sky;
      }
    }
  }

  &__cards {
    padding: 10px 0px 20px 0px;
    display: flex;
    @media (min-width: $lg) {
      max-width: 1200px;
    }
  }

  &__card-test {
    display: flex;
    background-color: $white;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 40px 20px;
    width: 100%;
    max-width: 230px;
    border-radius: 50px;
    height: 330px;
    box-shadow: 0px 4px 23px 4px rgba(0, 0, 0, 0.03);
    margin: 0px 10px;
    max-width: 170px;

    @media (min-width: $sm) {
      max-width: 270px;
    }

    img {
      padding: 30px;
      border-radius: 50%;
      background: $white-found;
    }

    h3{
      padding: 10px 0px;
    }
  }
}

.exams-slider{
  height: 400px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 378px;
  position: relative;

  @media (min-width: $sm) {
    max-width: 650px;
  }

  @media (min-width: $md) {
    max-width: 850px;
  }

  @media (min-width: $lg) {
    max-width: 1200px;
  }
}

.slick-slide{
  height: 320px !important;
}


.slick-arrow{
  cursor: pointer;
}

.fa-arrow-left, .fa-arrow-right{
  font-size: 25px;
}

.fa-arrow-left{
  position: absolute;
  bottom: 40px;
  left: 44%;
  color: $blue-bondi;
  margin-right: 20px;
}

.fa-arrow-right{
  position: absolute;
  bottom: 40px;
  right: 44%;
  color: $blue-bondi;
}

.block-blog {
  display: none !important;
  width: 100%;
  padding: 40px 10px;
  height: 330px;
  background: $yellow;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__title {
    font-size: 2.3rem;
  }
  button {
    padding: 8px 25px;
    margin-top: 30px;
    img {
      padding-left: 10px;
    }
  }
}

.blog-access{
  padding: 40px 0px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media (min-width: $sm) {
    text-align: left;
  }

  h2{
    padding: 10px 0px 20px 0px;
  }

  &__wrapper{
    display: flex;
    gap: 20px;
    padding: 20px 0px;
    flex-direction: column;
    align-items: center;

    @media (min-width: $sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $lg) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.blog-access-item{
  max-width: 280px;
  width: 100%;
  height: 290px;
  padding: 10px 0px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: $white-found;

  &:hover{
  .blog-access-item__image > img{
    transform: scale(1.04);
  }
}

  &__image{
    max-width: 100%;
    height: 150px;
    overflow: hidden;
    margin-bottom: 20px;

    img{
      width: 100%;
      object-fit: none;
      transition: all 0.3s ease;
    }
  }

}


