.protection {
    height: 100%;
    background: url("/img/bg-direct.png") no-repeat;
    background-size: cover;
    &__wrapper {
      padding: 60px 0px;
      background: linear-gradient(130deg, rgba(255, 255, 255, 0.897) 30%, rgba(161, 156, 156, 0.856) 53%, rgba(124, 124, 124, 0.63) 100%);
      height: 100%;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-width: 530px;
  
      img {
        max-width: 218px;
      }
  
      h2 {
        font-size: 2.3rem;
        color: $black;
        padding: 25px 0px;
      }
  
      span {
        color: $blue-bondi;
      }
  
      p {
        color: $black;
        line-height: 28px;
        font-weight: 500;
      }
    }
  
    &__redirect {
      display: flex;
      flex-direction: column;
  
      @media (min-width: $md) {
        flex-direction: unset;
      }
      a img{
        margin-left: 10px;
      }
    }
  
    &__website {
      margin: 70px 5px 0px 0px;
      padding: 8px 25px;
  
      &:hover {
        transition: all 0.8s;
      }
  
      img {
        padding: 0px 5px;
  
        &:first-child {
          padding-left: 10px;
        }
      }
    }
    &__redirect-socials {
      display: flex;
      padding-top: 10px;
      align-items: flex-end;
  
      img {
        max-width: 50px;
      }
  
      a {
        max-height: 60px;
        padding: 10px 5px;
  
        &:hover {
          transition: all 0.3s;
          transform: scale(0.9);
        }
  
        @media (min-width: $md) {
          padding: 0px 10px;
        }
      }
    }
  }
