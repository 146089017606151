.blog-news {
  display: flex;
  background: url(/img/bg-blog.png);
  background-position: center center;
  padding: 60px 0px;

  .container {
    width: 100%;
  }

  &__title {
    font-size: 2.3rem;
    padding-bottom: 30px;
  }
  
  &__subtitle{
    color: $orange-class;
  }
  
  &__result{
    color: #707070;
    font-weight: 500;
    margin-top: 10px;
  }

  &__notice-title {
   font-size: 1.5rem;
    height: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;

    @media (min-width: $sm) {
      font-size: 2rem;
    }
  }

  &__grid {
    display: grid;
    width: 100%;
    gap: 20px;


    @media (min-width: $lg) {
      grid-template-columns: minmax(180px, 675px) 1fr;
    }
  }

  &__main {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: url('https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg')
    no-repeat;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    @media (min-width: $sm) {
      max-width: unset;
    }

    &:hover{
      .blog-news__main-notice{
        transform: scale(1.02);
      }
    }
  }

  &__main-notice {
    height: 176px;
    width: 100%;
    background-color: $white;
    border-radius: 25px 0px 25px 0px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all 0.3s ease;
  }

  &__notice-image {
    height: 150px;
    max-width: 190px;
    overflow: hidden;
    border-radius: 8px;

    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.4s ease;
    }

    @media (min-width: $sm) {
      height: 123px;
    }
  }

  &__sequence {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__sequence-notice {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 28px;
    background: $white;


    @media (min-width: $sm) {
      max-height: 153px;
      height: unset;
    }

    @media (min-width: $lg) {
      max-width: 494px;
      min-width: 410px;
    }

    &:hover{
      .blog-news__notice-image > img{
        transform: scale(1.1);
      }
    }
  }

  &__notice-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
  }
}

.blog-notice {
  max-width: 400px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:hover{
    .blog-notice__image > img{
      transform: scale(1.04);
    }
  }

  &__image{
    overflow: hidden;
    border-radius: 18px;
    max-height: 230px;

    img{
      max-width: 100%;
      transition: all 0.3s ease;
    }
  }

  &__category {
    color: $orange-class;
    font-size: 0.9rem;
    font-weight: 500;
    // padding-bottom: 5px;
  }

  &__title {
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 90px;
    display: flex;
    align-items: center;
    padding-bottom: 12px;

    @media (min-width: $sm) {
      max-height: 73px;
    }
  }


  &__title-news{
    max-height: 110px;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  &__link {
    color: #191e23;
    text-transform: uppercase;
    font-size: 0.83rem;
    font-weight: 400;
    padding-right: 20px;
    position: relative;
    max-width: 110px;

    &::after {
      content: '+';
      position: absolute;
      right: 33px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.blog-posts {
  width: 100%;
  padding: 40px 0px;

  .btn-blue{
    max-width: 170px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }


  &__grid {
    padding: 40px 0px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-row-gap: 35px;
    grid-column-gap: 15px;
    height: 100%;


    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
