$black: #000;
$white: #ffffff;
$white-found: #F4F9FD;
$blue-light: #add6f2;
$blue-sky: #6a8bd9;
$blue-bondi: #315171;
$yellow: #fedba3;
$gray-text: #707070;
$orange-class:#D89843;
$pink:#F48483;
