/*
*
*      ██╗   ██╗███████╗███╗   ██╗██████╗  ██████╗ ██████╗ 
*      ██║   ██║██╔════╝████╗  ██║██╔══██╗██╔═══██╗██╔══██╗
*      ██║   ██║█████╗  ██╔██╗ ██║██║  ██║██║   ██║██████╔╝
*      ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║  ██║██║   ██║██╔══██╗
*       ╚████╔╝ ███████╗██║ ╚████║██████╔╝╚██████╔╝██║  ██║
*        ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝
*
*/

// Reboot
// @import '../../node_modules/bootstrap/scss/bootstrap-reboot.scss';

/*
*      
*      ██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗███████╗
*      ██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝██╔════╝
*      ██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║   ███████╗
*      ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║   ╚════██║
*      ██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║   ███████║
*      ╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
*      
*/

// Colors
@import './utils/colors';

// Mixins
@import './utils/mixins';

/* Animations */
@import './utils/animations';

/* Variables */
@import './utils/variables';

/* Typography */
@import './base/typography';

/* Defaults */
@import './base/defaults';

/* Common */
@import './utils/common';


/*
*       ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗███████╗
*       ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
*       ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║   ███████╗
*       ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
*       ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║   ███████║
*        ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
*/                                                                                          


/* Header */
@import './components/header';

/* Footer */
@import './components/footer';

/* Fale Conosco */
@import './components/about-us';

@import './components/protection';

/*
*
*      ██████╗  █████╗  ██████╗ ███████╗███████╗
*      ██╔══██╗██╔══██╗██╔════╝ ██╔════╝██╔════╝
*      ██████╔╝███████║██║  ███╗█████╗  ███████╗
*      ██╔═══╝ ██╔══██║██║   ██║██╔══╝  ╚════██║
*      ██║     ██║  ██║╚██████╔╝███████╗███████║
*      ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝
*
*
*/

/* Home */
@import './pages/home';

/* Blog */
@import './pages/blog';

/* Blog Internal */
@import './pages/blog-internal';
