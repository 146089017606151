.footer{
    padding: 60px 0px 10px 0px;
    width: 100%;

    &__copyright{
        font-size: 0.7rem;
        padding: 20px 5px;
        color: #70707091;
    }

    &__dev{
        transition: all 0.7s;

        &:hover{
            color: $blue-bondi;
            font-weight: bold;
        }
    }
}

.footer-nav{
    padding-bottom: 35px;
    border-bottom: 1px solid #70707044;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;

    @media (min-width: $md) {
            flex-direction: row;
    }

    &__wrapper{
        display: grid;
        justify-items: center;
        font-family: 'Roboto', sans-serif;
        width: 100%;
        gap: 20px;
        
        @media (min-width: $sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }

        @media (min-width: $lg) {
            display: flex;
            align-items: center;
        }
        
        li{
            padding: 5px 13px;
            font-size: 0.8rem;
      
            &:hover{
                transition: all 0.3s;
                color: $blue-sky;
            }

        }
    
    }

    &__logo{
        list-style: none;
    }
}