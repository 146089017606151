
.contact {
    display: flex;
    align-items: center;
    background: url("/img/bg-contact.png") no-repeat center center;
    background-size: cover;
  
    &__wrapper {
      height: 100%;
      width: 100%;
      padding: 80px 0px 40px 0px;
      background: rgba(239, 246, 255, 0.877);
    }
  
    h2 {
      text-align: center;
      font-size: 2.3rem;
      line-height: 60px;
    }
  
    span {
      font-size: 2rem;
      font-weight: normal;
    }
  
    &__content {
      padding: 30px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 50px;
      width: 100%;
      height: 100%;
      flex-direction: column;
  
      @media (min-width: $lg) {
        flex-direction: row;
        gap: 10px;
      }
    }
  
    &__content-form {
      form {
        padding: 40px 60px;
        font-family: "Roboto", sans-serif;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        border-radius: 25px;
        background-color: $white;
  
        h3 {
          padding: 20px 0px;
        }
  
        p {
          font-size: 0.9rem;
          padding: 20px 0px;
        }
  
        input,
        textarea {
          font-family: "Roboto", sans-serif;
          max-width: 440px;
          height: 45px;
          border-radius: 5px;
          padding: 10px;
          border: 1px solid #84adc8;
          margin: 10px 5px;
  
          &::placeholder{
             font-size: 0.7rem;
             color: $gray-text;
             font-family: 'Montserrat', sans-serif;
          }
  
          &:focus{
            outline: none;
          }
        }
  
        textarea {
          min-height: 100px;
          font-family: "Roboto", sans-serif;
          resize: none;
        }
  
        button {
          float: right;
          max-width: 200px;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  
    &__socials {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
  
      @media (min-width: $lg) {
        max-width: 600px;
      }
    }
  
    &__socials-card {
      max-width: 530px;
      width: 100%;
      height: 100%;
      padding: 15px 50px;
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: $blue-bondi;
      margin-bottom: 20px;
      border-radius: 12px;
      box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.29);
      justify-content: center;
      animation: glow-external 1.5s infinite alternate;
      transition: ease-out 0.3s;
  
      &:hover{
        transform: scale(1.05);
      }
  
      @media (min-width: $md) {
        justify-content: flex-start;
      }
  
      @media (min-width: $lg) {
        height: 140px;
        padding: 10px 50px;
      }
  
      article {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
  
      p {
        font-size: 1.8rem;
        color: $white;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        display: none;
        font-family: 'Montserrat', sans-serif;
  
        @media (min-width: $md) {
          display: block;
        }
      }
  
      span {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
        color: $yellow;
        padding: 20px 0px;
        font-family: 'Montserrat', sans-serif;
      }
  
      i {
        font-size: 4rem;
        color: $yellow;
      }
  
      &--links {
        display: flex;
        align-items: center;
        gap: 10px;
  
        a {
          &:hover {
            transition: all 0.3s;
            transform: scale(0.9);
          }
        }
      }
    }
  }